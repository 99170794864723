.calendarWrap {
    position: relative;
    width: 100%;
  }
  
  .calendarElement {
    position: absolute;
    z-index: 1000; /* Ensure the calendar pops above other elements */
  }

  .truncate {
    max-width: 150px; /* Adjust the width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}