.chart-data-section {
    height: auto;
    transform: scale(1);
    transition: 0.2s;
    /* margin: 20px; */
}


.chart-data-section:hover{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;            /* animation: bouncing 0.5s .3s; */
            transition: 0.2s;
            cursor: pointer;
}

.show-count{
    height: auto;
    padding: 20px;
    border: 1px solid #A9A9A9;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}


.chart-count{
    font-weight: 500;
    font-size: 50px;
}


.card-hover {
    position: relative; /* Ensure relative positioning for absolute elements */
  }
  
  .card-hover::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 5px; /* Width of the left border line */
    background-color: transparent; /* Initially transparent */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
  }
  
  .card-hover:hover::before {
    background-color: #4e73df; /* Color of the left border on hover */
  }